
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$StudioPortal-primary: mat.define-palette(mat.$indigo-palette);
$StudioPortal-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$StudioPortal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$StudioPortal-theme: mat.define-light-theme((
  color: (
    primary: $StudioPortal-primary,
    accent: $StudioPortal-accent,
    warn: $StudioPortal-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($StudioPortal-theme);

/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";
@import "@angular/material/prebuilt-themes/indigo-pink.css";

:root {
    --side-bar-width: 250px;
    --header-height: 77px;
    --primary-color: #1D3E66FF;
    --background-color: #f1f3f8;
    --white-color: #fff;
    --common-radius: 8px;
    --high5-secondary: #f69b03;
}

body {
    margin: 0px;

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 999px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--primary-color);
        border-radius: 999px;
    }

    scroll-behavior: smooth;
}
// loader css start
.loader-overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999999999999;

    .loader-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00000047;
    }

    .loader-spinner-box {
        padding: 15px;
        background: #fff;
        display: flex;
        gap: 16px;
        border-radius: 4px;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;

        .loader {
            width: 40px;
            padding: 4px;
            aspect-ratio: 1;
            border-radius: 50%;
            background: var(--primary-color);
            --_m:
                conic-gradient(#0000 10%, #000),
                linear-gradient(#000 0 0) content-box;
            -webkit-mask: var(--_m);
            mask: var(--_m);
            -webkit-mask-composite: source-out;
            mask-composite: subtract;
            animation: l3 1s infinite linear;
            z-index: 55555555;
        }

        .loading-text {
            max-width: 200px;
            white-space: break-spaces;
            word-wrap: break-word;
        }
    }

}

@keyframes l3 {
    to {
        transform: rotate(1turn)
    }
}

// loader css end

.cmn-container {
    height: calc(100vh - var(--header-height));
    overflow: auto;
    padding: 16px;
}

.empty-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

// font color css start
.text-primary {
    color: var(--primary-color);
}

.text-black-100 {
    color: #111111;
}

.text-black-500 {
    color: #555555;
}

.text-danger {
    color: #ff0000;
}

// font color css end


// background-color css start
.bg-secondary {
    background-color: var(--background-color);
}

.bg-primary {
    background-color: var(--primary-color);
}

// background-color css end

// grid css start

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

[class^="col-"] {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
    padding: 0 15px;
}

.col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
    padding: 0 15px;
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 15px;
}

.col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding: 0 15px;
}

.col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
    padding: 0 15px;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px;
}

.col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
    padding: 0 15px;
}

.col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    padding: 0 15px;
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
    padding: 0 15px;
}

.col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    padding: 0 15px;
}

.col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
    padding: 0 15px;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 15px;
}

.d-flex {
    display: flex;
}

.flex-1{
  flex: 1;
}

.flex-col {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-grow {
    flex-grow: 1;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-end {
    justify-content: end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.align-items-center {
    align-items: center !important;
}

.gap-16px {
    gap: 16px;
}

.gap-10px {
    gap: 10px;
}

.gap-20px{
  gap: 20px;
}
.text-start {
    text-align: start;
}

.text-end {
    text-align: end;
}

.text-center {
    text-align: center;
}

.w-full {
    width: 100%;
}

.w-50 {
    width: 50% !important;
}

.w-40{
  width: 40% !important;
}

.w-60{
  width: 60% !important;
}

.h-50vh {
    height: 50vh;
}

.h-full {
    height: 100%;
}

.w-h-20 {
    width: 20px;
    height: 20px;
}

.w-h-40 {
  width: 40px;
  height: 40px;
}

.cursor-pointer {
    cursor: pointer;
}
// grid css end

// cmn-toggle css start
.cmn-toggle {
    .p-inputswitch.p-inputswitch-checked {
        .p-inputswitch-slider {
            background: var(--primary-color);
        }
    }
}
// cmn-toggle css end

p-dialog.cus-dialog {
    .p-dialog-title {
        color: var(--primary-color);
        width: 100%;
        text-align: center;
    }
}

.contact-dialog {
  .event-heading {
      h4 {
          color: #777777;
          font-weight: 400;
          margin: 0 0 10px 0;
          font-size: 20px;

          span {
              color: var(--primary-color);
              font-weight: 600;
              font-size: 20px;
          }
      }
  }


  .p-dialog-header-icons {
      display: none;
  }

  .p-dialog-content:last-of-type {
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;
  }

  .p-dialog-header {
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
  }

  .p-dialog-content {
      padding: 10px 36px 36px 36px;
  }
}

p-confirmDialog.cus-confirmDialog {
    .p-component-overlay {
        .p-confirm-dialog {
            max-width: 700px;

            .p-dialog-content {
                .p-confirm-dialog-message {
                    word-wrap: break-word;
                    white-space: break-spaces;
                    margin: 0;
                    width: 100%;
                }
            }
        }
    }
    .p-confirm-dialog {
        position: relative;
    }

    .p-dialog-header {
        color: var(--primary-color);
    }

    .p-dialog-header-icons {
        position: absolute;
        top: 7px;
        right: 8px;
    }

    .p-confirm-dialog-accept {
        background-color: var(--primary-color);
        border: none;
        color: var(--white-color);
        font-size: 14px;
        padding: 11px 18px;
        text-align: center;
        &:focus {
            box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #1d3e65e3, 0 1px 2px 0 black;
        }
    }

    .p-confirm-dialog-reject {
        color: var(--primary-color);
        background: transparent;
        border: none;
        font-size: 14px;
        padding: 11px 18px;
        text-align: center;
        &:focus {
            box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #1d3e65e3, 0 1px 2px 0 black;
        }
        &:hover {
            background-color: #3b82f60a;
        }
    }
}
ngx-mat-timepicker-content {
    mat-toolbar {
        &.mat-toolbar.mat-primary {
            --mat-toolbar-container-background-color: var(--primary-color);
        }
    }
    .mat-mdc-mini-fab.mat-primary {
        --mdc-fab-small-container-color: var(--primary-color);
    }
}

.mat-mdc-button.mat-primary {
    --mdc-text-button-label-text-color: var(--primary-color);
    --mat-text-button-state-layer-color: var(--primary-color);
}
// button css start
p-button.cmn-btn-primary {
    &.p-disabled {
        cursor: not-allowed !important;
    }
    .p-ripple {
        &:focus {
            box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #1d3e65e3, 0 1px 2px 0 black;
        }
        background-color: var(--primary-color);
        border: none;
        width: 100%;
        justify-content: center;
        gap: 8px;
        align-items: center;
        font-size: 14px;
        padding: 11px 18px;
        border-radius: var(--common-radius);

        img {
            width: 18px;
            height: 18px;
        }

        .p-button-label {
            font-size: 14px;
            font-weight: normal;
        }
    }

    &.btn-with-icon {
        min-width: 130px;

        .p-ripple {
            min-width: 130px;
        }
    }

    &.rounded-full {
        .p-ripple {
            border-radius: var(--common-radius);
        }
    }

    &.w-full {
        .p-button {
            width: 100%;
        }
    }
    &.btn-sm{
        .p-ripple {
          padding: 4px 8px;
        }
    }

    &.outlined {
        &.btn-sm {
            .p-ripple {
                padding: 9px 15px;
            }
        }

        .p-ripple {
            background-color: transparent;
            color: var(--primary-color);
            border: 1px solid var(--primary-color);
            padding: 9px 18px;
        }
    }
}

p-button.cmn-btn-secondary {
    .p-ripple {
        background-color: var(--background-color);
        border: 1px solid var(--background-color);
        width: 100%;
        color: var(--primary-color);
        justify-content: center;
        gap: 8px;
        align-items: center;
        font-size: 14px;
        padding: 10px 18px;
        border-radius: var(--common-radius);

        &:focus {
            box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #1d3e65e3, 0 1px 2px 0 black;
        }
        img {
            width: 18px;
            height: 18px;
        }

        .p-button-label {
            color: var(--primary-color);
            font-size: 16px;
            font-weight: normal;
        }
    }

    &.rounded-full {
        .p-ripple {
            border-radius: var(--common-radius);
        }
    }

    &.btn-sm{
        .p-ripple {
          padding: 4px 8px;
        }
    }

    &.btn-with-icon {
        min-width: 130px;

        .p-ripple {
            min-width: 130px;
        }
    }

    &.w-auto {
        .p-ripple {
            width: auto;
        }
    }
}

p-button.cmn-icon-btn-secondary {
    .p-button {
        padding: 11px;
        &:focus {
            box-shadow: none;
        }
        &.p-button-text:not(:disabled):active {
            background: var(--background-color);
            border-color: transparent;
        }
    }
}

// button css end

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

//margin css start

.ms-auto {
    margin-left: auto;
}

.mt-50 {
    margin-top: 50px;
}

.mt-auto {
    margin-top: auto;
}

.mx-auto {
    margin: 0 auto;
}

.m-0 {
    margin: 0;
}

.m-1 {
    margin: 0.25rem;
}

.m-2 {
    margin: 0.5rem;
}

.m-3 {
    margin: 1rem;
}

.m-4 {
    margin: 1.5rem;
}

.m-5 {
    margin: 3rem;
}

.mt-30px {
    margin-top: 30px;
}

.mt-0 {
    margin-top: 0;
}

.mt-1 {
    margin-top: 0.25rem;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mt-3 {
    margin-top: 1rem;
}

.mt-4 {
    margin-top: 1.5rem;
}

.mt-5 {
    margin-top: 3rem;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-1 {
    margin-bottom: 0.25rem;
}

.mb-2 {
    margin-bottom: 0.5rem;
}

.mb-3 {
    margin-bottom: 1rem;
}

.mb-4 {
    margin-bottom: 1.5rem;
}

.mb-5 {
    margin-bottom: 3rem;
}

.ml-0 {
    margin-left: 0;
}

.ml-1 {
    margin-left: 0.25rem;
}

.ml-2 {
    margin-left: 0.5rem;
}

.ml-3 {
    margin-left: 1rem;
}

.ml-4 {
    margin-left: 1.5rem;
}

.ml-5 {
    margin-left: 3rem;
}

.mr-auto{
  margin-right: auto;
}

.mr-0 {
    margin-right: 0;
}

.mr-1 {
    margin-right: 0.25rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mr-3 {
    margin-right: 1rem;
}

.mr-4 {
    margin-right: 1.5rem;
}

.mr-5 {
    margin-right: 3rem;
}

//margin css end

// gap css start
.gap-0 {
    gap: 0;
}

.gap-1 {
    gap: 0.25rem;
}

.gap-2 {
    gap: 0.5rem;
}

.gap-3 {
    gap: 1rem;
}

.gap-4 {
    gap: 1.5rem;
}

.gap-5 {
    gap: 3rem;
}

.gap-30px {
    gap: 30px;
}
.gap-y-30px {
    gap: 30px 0;
}

.gap-y-24px {
    gap: 24px 0;
}

.gap-y-20px {
    gap: 20px 0;
}

.gap-24px {
    gap: 24px;
}

// gap css end

// padding css start
.p-0 {
    padding: 0;
}

.p-1 {
    padding: 0.25rem;
}

.p-2 {
    padding: 0.5rem;
}

.p-3 {
    padding: 1rem;
}

.p-4 {
    padding: 1.5rem;
}

.p-5 {
    padding: 3rem;
}

.pt-0 {
    padding-top: 0;
}

.pt-1 {
    padding-top: 0.25rem;
}

.pt-2 {
    padding-top: 0.5rem;
}

.pt-3 {
    padding-top: 1rem;
}

.pt-4 {
    padding-top: 1.5rem;
}

.pt-5 {
    padding-top: 3rem;
}

.pb-0 {
    padding-bottom: 0;
}

.pb-1 {
    padding-bottom: 0.25rem;
}

.pb-2 {
    padding-bottom: 0.5rem;
}

.pb-3 {
    padding-bottom: 1rem;
}

.pb-4 {
    padding-bottom: 1.5rem;
}

.pb-5 {
    padding-bottom: 3rem;
}

.pl-0 {
    padding-left: 0;
}

.pl-1 {
    padding-left: 0.25rem;
}

.pl-2 {
    padding-left: 0.5rem;
}

.pl-3 {
    padding-left: 1rem;
}

.pl-4 {
    padding-left: 1.5rem;
}

.pl-5 {
    padding-left: 3rem;
}

.pr-0 {
    padding-right: 0;
}

.pr-1 {
    padding-right: 0.25rem;
}

.pr-2 {
    padding-right: 0.5rem;
}

.pr-3 {
    padding-right: 1rem;
}

.pr-4 {
    padding-right: 1.5rem;
}

.pr-5 {
    padding-right: 3rem;
}

.px-0 {
    padding-left: 0;
    padding-right: 0;
}

.px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

// padding css end

// font css start
.fs-24 {
    font-size: 24px;
}

.fs-22 {
    font-size: 22px;
}

.fs-20 {
    font-size: 20px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-15 {
    font-size: 15px;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

// font css end

.img-primary {
    filter: invert(9%) sepia(78%) saturate(4799%) hue-rotate(233deg) brightness(77%) contrast(102%);
}

//table css start
p-table.cmn-table {
    .p-datatable-thead {
        th {
            background-color: var(--primary-color);
            color: var(--white-color);
            font-weight: 600;
            font-size: 15px;
        }

        th:nth-child(1) {
            border-radius: var(--common-radius) 0 0 0 !important;
        }

        th:nth-last-child(1) {
            border-radius: 0 var(--common-radius) 0 0 !important;
        }
    }
    &.header-with-btn{
        .p-datatable-thead > tr > th {
            padding: 11px;
        }
    }

    tbody {
        tr {
            td {
                font-size: 14px;
                padding: 0 8px;
                height: 55px;
            }

            td:nth-child(1) {
                border-left: 1px solid #e5e7eb;
            }

            td:nth-last-child(1) {
                border-right: 1px solid #e5e7eb;
            }
        }

        tr:nth-last-child(1) {
            td:nth-child(1) {
                border-radius: 0 0 0 var(--common-radius);
            }

            td:nth-last-child(1) {
                border-radius: 0 0 var(--common-radius) 0;
            }
        }
    }
}

.tr-h-50px {
    height: 50px;
}

//table css end

//image css start
.action-icon {
    width: 36px;
    height: 36px;
    padding: 0 8px;
    background-color: var(--background-color);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    cursor: pointer;

    &.w-h-full {
        padding: 0;

        img {
            width: 100%;
            height: 100%;
        }
    }

    img {
        width: 20px;
        height: 20px;
    }

    &:hover {
        background-color: #e4e9f6;
    }
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

//image css end

// width css start
.w-22 {
    width: 22px;
}

.h-22 {
    height: 22px;
}



.min-w-375px {
    min-width: 375px;
}

.w-h-26px {
    width: 26px;
    height: 26px;
}

.w-h-24px {
    width: 24px;
    height: 24px;
}

.w-h-30px {
    width: 30px;
    height: 30px;
}

.min-w-130px {
    min-width: 130px;
}

.min-w-30px {
    min-width: 30px;
}

.min-w-120px {
    min-width: 120px;
}

.min-w-200px {
    min-width: 200px;
}
.min-w-250px {
    min-width: 250px;
}

.max-w-200px {
    max-width: 200px;
}

.max-w-250px {
    max-width: 250px;
}

.max-w-300px {
  max-width: 300px;
}

.max-w-400px {
    max-width: 400px;
}

.max-w-800px {
    max-width: 800px;
}

.max-w-700px {
    max-width: 700px;
}

// width css end

// height css start

.min-h-140px {
    min-height: 140px;
}

.min-h-36px {
    min-height: 36px;
}

.min-h-150px {
    min-height: 150px;
}

// height css end

// dropdown css start

p-dropdown.cmn-dropdown {
    display: block;

    .p-dropdown {
        width: 100%;
    }

    .p-highlight {
        color: var(--primary-color);
    }
    &.filter-input {
        .p-dropdown-panel {
            .p-dropdown-header {
                .p-dropdown-filter {
                    margin-right: -4px;
                }
            }
        }
    }
}

.action-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filter-section {
        width: 100%;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        align-items: center;

        .p-input-icon-left {
            width: 30%;

            i {
                color: var(--primary-color);
            }

            input {
                width: 100%;
                border-radius: var(--common-radius);
                color: var(--primary-color);

                &::placeholder {
                    color: var(--primary-color);
                    opacity: 0.7;
                }
            }
        }

        p-dropdown {
            width: 20%;

            .p-dropdown {
                width: 100%;
                border-radius: var(--common-radius);
            }

            .p-dropdown-label,
            .p-placeholder {
                color: var(--primary-color);
            }
        }
    }
}

// dropdown css end

.position-relative {
    position: relative;
}

// input css start
.cus-float-label {
    width: 100%;

    input:focus~label,
    input.p-filled~label,
    .p-inputwrapper-filled~label,
    .p-inputwrapper-focus~label,
    textarea.p-filled~label,
    textarea:focus~label {
        top: 4px;
        font-size: 12px;
        background: #fff;
        font-weight: 400;
        color: var(--primary-color);
    }

    &.input-sm {
        .p-inputtext {
            padding: 8px;
            font-size: 12px;
        }

        input:focus~label,
        input.p-filled~label,
        .p-inputwrapper-filled~label,
        .p-inputwrapper-focus~label,
        textarea.p-filled~label,
        textarea:focus~label {
            font-size: 10px;
        }
    }

    &.w-full {
        .p-dropdown {
            width: 100%;
        }
    }
}

.invalid-feedback {
    color: red;
    font-size: 12px;
    padding-top: 4px;
}

.time-picker {
    &.input-sm {
        .p-inputtext {
            font-size: 12px;
            padding: 6.5px;
        }
    }
}


.cus-calendar {
    .p-calendar {
        width: 100%;
    }

    &.input-sm {
        .p-inputtext {
            font-size: 12px;
            padding: 6.5px;
        }
    }
}

// input css end
// tabView css start
p-tabView.custom-tabview {
    display: block;
    height: 100%;

    .p-tabview {
        display: flex;
        flex-direction: column;
        height: 100%;

        .p-tabview-panels {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            p-tabpanel {
                // display: none;

                &.active-tab {
                    &.justify-content-start {
                        justify-content: start;
                        align-items: unset;
                    }

                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .p-tabview-nav-container {
            .p-tabview-nav-content {
                ul.p-tabview-nav {
                    li {
                        a.p-element {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            justify-content: center;
                        }

                        a.p-tabview-nav-link {
                            font-weight: 600;
                            padding: 16px;
                        }

                        &.p-highlight {
                            a.p-tabview-nav-link {
                                color: var(--primary-color);
                                border-color: var(--primary-color);
                            }
                        }
                    }
                }
            }
        }
    }

    &.secondary {
        .p-tabview-nav {
            background-color: var(--background-color);
            border-radius: 9999px;
            border: none;
            padding: 2px;
            gap: 24px;
        }

        li .p-tabview-nav-link {
            border-color: transparent;
        }

        .p-tabview-nav-container {
            .p-tabview-nav-content {
                ul.p-tabview-nav {
                    li {
                        a.p-element {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            justify-content: center;
                        }

                        a.p-tabview-nav-link {
                            font-weight: 600;
                            padding: 8px;
                            background-color: var(--background-color);
                            color: var(--primary-color);
                            border-radius: 9999px;
                            box-shadow: none;
                            transition: 0.2s;
                            font-size: 14px;
                        }

                        &.p-highlight {
                            .tab-icon {
                                filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(310deg) brightness(104%) contrast(101%);
                            }

                            a.p-tabview-nav-link {
                                color: var(--background-color);
                                background-color: var(--primary-color);
                            }
                        }
                    }
                }
            }
        }

        &.w-full {
            .p-component {
                .p-tabview-nav-container {
                    .p-tabview-nav-content {
                        ul.p-tabview-nav {
                            li {
                                flex-grow: 1;

                                a.p-tabview-nav-link {
                                    min-height: 42px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    .p-tabview-title {
                                        line-height: unset;
                                    }
                                }
                            }
                        }
                    }
                }

                .p-tabview-nav {
                    gap: 0;
                }
            }
        }
    }
}

// tabView css end

// cus-stepper start
p-stepper.cus-stepper {
    .p-stepper-header {
        .p-stepper-action {
            .p-stepper-number {
                color: black;
                line-height: unset;
                font-size: 16px;
            }
        }
        .p-stepper-title {
            font-weight: normal;
        }
    }

    .p-stepper-header.p-highlight {
        .p-stepper-number {
            background: var(--background-color);
            color: var(--primary-color);
            line-height: unset;
            font-size: 16px;
        }
        .p-stepper-title {
            color: var(--primary-color);
            font-weight: 600;
        }
    }

    .p-stepper-separator {
        height: 4px;
    }

    .p-stepper-header:has(~ .p-highlight) .p-stepper-separator {
        background-color: var(--primary-color);
    }
}
// cus-stepper end

// dialog start
p-dialog.cmn-dialog {
    .p-dialog-header {
        .p-dialog-title {
            margin: 0 auto;
            color: var(--primary-color);
            font-size: 26px;
            font-weight: 600;
        }
    }
}

// dialog end

// checkbox css start
p-checkbox.cus-checkbox {
    &.rounded-full {
        .p-checkbox-box {
            border-radius: 9999px;
        }
    }

    .p-checkbox-box.p-highlight {
        border-color: var(--primary-color);
        background: var(--primary-color);
    }
}

// checkbox css end


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
